import {
    Color,
    Scene,
    PerspectiveCamera,
    Vector3,
    PointLight,
    MathUtils,
    DirectionalLight,

} from 'three'
import { createAnimationCallback, createRenderer, debounce } from './utils/generic-renderer';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


export async function init() {

    const scene = new Scene();
    const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const url = 'assets/meshes/FacetedPaper.gltf';
    const lightAry = [];
    const lightPositions = [];
    const textMeshAry = [];

    let mouseRaw = new Vector3(0, 0, 0);
    let mousePos = new Vector3(0, 0, 0);
    let strength = 0.2;
    let colorLerpVal = 0;
    let forgroundLight;
    let topLight;
    let lightPosX = 0;
    let lightPosY = 0;
    let materialBKG;
    let snapBackInProgress = false;
    let snapBackTimer = 0.0;
    let snapBackForTime = 0;
    let lastTouchX = 0;
    let lastTouchY = 0;
    let touching = false;
    let smoothGyroOrientation = new Vector3(0, 0, 0);
    let portrait = true;

    document.title = "👻 Coming Soont 👻"

    window.addEventListener('mousemove', e => {
        mouseRaw.x = e.offsetX;
        mouseRaw.y = e.offsetY;
        status.innerText = `(${mouseRaw.x}, ${mouseRaw.y})`;
    });

    const status = document.createElement('div')
    document.body.append(status)
    status.style.zIndex = 100
    status.style.position = 'fixed'
    status.style.color = 'white'
    status.style.display = 'none'
    status.innerText = "(x, y)"

    const meta = document.createElement('meta');
    meta.setAttribute('name', 'viewport');
    meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    document.body.style.overscrollBehavior = 'contain';

    document.head.append(meta);

    function getAverageTouchXY(e) {
        let averageX = 0, averageY = 0;
        for (let touch of e.touches) {
            averageX += touch.pageX
            averageY += touch.pageY
        }

        averageY = Math.round(averageY / e.touches.length);
        averageX = Math.round(averageX / e.touches.length);

        return [averageX, averageY];
    }

    window.addEventListener('touchstart', e => {
        touching = true;
    });

    window.addEventListener('touchmove', e => {
        touching = true;

        let midPointX = window.innerWidth / 2;
        let midPointY = window.innerHeight / 2;

        let [averageX, averageY] = getAverageTouchXY(e);

        status.innerText = `(${averageX}, ${averageY})`;

        mouseRaw.x = (averageX * 2) - midPointX;
        mouseRaw.y = (averageY * 2) - midPointY;
    });

    window.addEventListener('touchend', e => {
        touching = false;
        snapBackInProgress = true;
        snapBackTimer = snapBackForTime;
        status.innerText += ` Snapping back`;
    });

    try {
        let accelerometer = new Accelerometer({ referenceFrame: 'screen', frequency: 60 });

        accelerometer.addEventListener('reading', (event) => {
            if (touching) {
                return;
            }
            const scaler = 125;
            const x = accelerometer.x * scaler;
            const y = accelerometer.y * scaler;

            status.innerText = `${x}, ${y}, ${accelerometer.z} ${portrait ? 'Portrait' : 'Landscape'}`;

            smoothGyroOrientation.x = MathUtils.lerp(smoothGyroOrientation.x, x, 0.1);
            smoothGyroOrientation.y = MathUtils.lerp(smoothGyroOrientation.y, y, 0.1);

            mouseRaw.x = smoothGyroOrientation.x;
            mouseRaw.y = smoothGyroOrientation.y - (scaler * 4);

        });
        accelerometer.start();
    } catch (e) {
        console.error(e);
    }

    // Handle mobile screen sized (height > width)
    const resizeListener = debounce(() => {
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;

        let ratio = (innerWidth / innerHeight) * 0.5;

        if (innerHeight > innerWidth) {
            scene.scale.set(ratio, ratio, ratio);
        } else {
            scene.scale.set(1, 1, 1);
        }

    }, 100);

    window.addEventListener("resize", resizeListener);

    resizeListener();

    const gltf = await gltfPromise(url);

    const mesh = gltf.scene.getObjectByName("Cube");
    mesh.receiveShadow = true;
    materialBKG = mesh.material;

    const lights = [
        { suffix: '', light: new PointLight(0x27f3f0, 1, 100) },
        { suffix: 'B', light: new PointLight(0x023FB4, 1, 100) },
        { suffix: 'C', light: new PointLight(0x023FB4, .2, 100) },
        //EXTRA SOFT LIGHT
        { suffix: 'D', light: new PointLight(new Color(1, 1, 1), 1, 25), topLight: true },
        //DIRECTION LIGHT
        { suffix: 'D', light: new DirectionalLight(0xff685b, .35), forgroundLight: true }
    ]

    for (let lightMeta of lights) {
        const poLight = gltf.scene.getObjectByName(`Light${lightMeta.suffix}`);
        const light = lightMeta.light;
        light.position.set(poLight.position.x, poLight.position.y, poLight.position.z);
        scene.add(light);
        lightPositions.push(poLight.position);
        if (lightMeta.topLight) {
            topLight = poLight
        }

        if (lightMeta.forgroundLight) {
            light.castShadow = true;
            light.shadow.radius = 8;
            forgroundLight = light;
        } else {
            lightAry.push(light);
        }
    }

    //grab text objects and push to ary
    for (let i = 0; i < 4; i++) {
        const textMesh = gltf.scene.getObjectByName(`Text00${i}`);
        textMesh.castShadow = true;
        textMeshAry.push(textMesh);
        scene.add(textMesh);
    }

    scene.add(mesh);


    //scene setup
    const renderer = createRenderer({ antialias: true });
    renderer.shadowMap.enabled = true;
    camera.position.z = 5;

    function render(time) {
        //Time:
        time *= 0.001;
        lastTouchX = mouseRaw.x;
        lastTouchY = mouseRaw.y;
        //Snap back on touch end:
        if (snapBackInProgress) {
            snapBackTimer += 0.01; //replace with delta time
            mouseRaw.x = MathUtils.lerp(lastTouchX, 0, snapBackTimer);
            mouseRaw.y = MathUtils.lerp(lastTouchY, 0, snapBackTimer);
            if (snapBackTimer >= 1) {
                snapBackTimer = 0;
                snapBackInProgress = false;
            }
        }

        //Move the array of cubes towards the mouse on different layers.
        mousePos.x = (mouseRaw.x / window.innerWidth) * 2 - 1;
        mousePos.y = - (mouseRaw.y / window.innerHeight) * 2 + 1;

        if (forgroundLight) {
            lightPosX = MathUtils.lerp(lightPosX, mousePos.x * 12, 0.01);
            lightPosY = MathUtils.lerp(lightPosY, mousePos.y * 12, 0.01);
            forgroundLight.position.x = lightPosX;
            forgroundLight.position.y = lightPosY;
        }

        for (let i = 0; i < textMeshAry.length; i++) {
            textMeshAry[i].position.x = mousePos.x * (i + 1) * -strength;
            textMeshAry[i].position.y = mousePos.y * (i + 1) * -0.2;
        }

        const radius = 1.25;
        const speed = 0.25;

        for (let i = 0; i < lightAry.length; i++) {
            lightAry[i].position.x = Math.sin(time * speed) * radius * lightPositions[i].x;
            lightAry[i].position.y = Math.cos(time * speed) * radius * lightPositions[i].y;
        }

        colorLerpVal += 0.01;

        const r = MathUtils.lerp(0.8, 0.5, Math.abs(Math.sin(colorLerpVal)));
        const g = MathUtils.lerp(0, 0.8, Math.abs(Math.sin(colorLerpVal)));
        const b = MathUtils.lerp(0.8, 0.5, Math.abs(Math.sin(colorLerpVal)));

        if (topLight) topLight.color = new Color(r, g, b);

        //UV
        if (materialBKG) materialBKG.map.offset.x = -time * 0.01;
        if (materialBKG) materialBKG.map.offset.y = -time * 0.01;
    };

    const paint = createAnimationCallback({
        preRender: render,
        renderer,
        scene,
        camera
    });

    paint(0);
}

function gltfPromise(url) {
    const gltfLoader = new GLTFLoader();
    return new Promise((resolve, reject) => {
        gltfLoader.load(url, resolve, null, reject);
    });
}