import { PerspectiveCamera, WebGLRenderer } from "three";

export function createAnimationCallback(config) {

    const preRender = config.preRender;
    const postRender = config.postRender;
    const renderer = config.renderer;
    const scene = config.scene
    const camera = config.camera

    makeCanvasResponsive(config.renderer, config.camera)

    window._debug = { scene, camera, renderer }
    console.log(window._debug)

    return function paint(time) {
        requestAnimationFrame(paint);

        preRender && preRender(time);

        renderer.render(scene, camera);

        postRender && postRender(time);

    }
}

/**
 *  Creates a renderer and adds the default canvas to the view
 * @param {import("three").WebGLRendererParameters} props 
 */
export function createRenderer(props) {
    // Create renderer and append canvas
    const renderer = new WebGLRenderer(props);
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    return renderer;
}

/**
 * Adds a window resize listener to update the canvas size.
 * 
 * @param {WebGLRenderer} renderer 
 * @param {PerspectiveCamera} camera 
 */
export function makeCanvasResponsive(renderer, camera) {
    const listener = debounce(() => {
        const canvas = renderer.domElement;
        renderer.setSize(window.innerWidth, window.innerHeight);
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
    }, 100);

    window.addEventListener("resize", listener);
}


/**
 * Waits for the events to "settle" before handling the event
 * 
 * @param {Function} fn 
 * @param {Number} wait 
 */
export function debounce(fn, wait) {
    let timeoutId;
    wait = wait || 500;
    return function () {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(fn, wait);
    };
}